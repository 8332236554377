window.addEventListener('load', () => {
  if ($("#cComite").length) {
    $('#cComite').flexslider({
      animation: "slide",
      animationLoop: true,
      itemWidth: 228,
      itemMargin: 26,
      minItems: 1,
      maxItems: 3
    });
  }
});